<template>
    <div class="main">

        <div class="actionBar">
            <a-space>
                <a-tree-select allowClear v-model="listParams.cat_id" style="width: 140px" :tree-data="classify"
                    placeholder="分类" :replaceFields="{ value: 'id' }" />
                <a-tree-select allowClear v-model="listParams.status" style="width: 100px" :tree-data="check_status"
                    placeholder="状态" />

                <a-range-picker style="width: 280px" v-model="time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                    :locale="locale" @change="timeChange">
                </a-range-picker>

                <a-select allowClear v-model="listParams.flag" style="width: 140px" placeholder="标签">
                    <a-select-option value="1">
                        1
                    </a-select-option>
                    <a-select-option value="2">
                        2
                    </a-select-option>
                    <a-select-option value="3">
                        3
                    </a-select-option>
                    <a-select-option value="4">
                        4
                    </a-select-option>
                </a-select>

                <a-input-search v-model="listParams.nickname" placeholder="用户名称" enter-button="查询" style="width: 250px"
                    allowClear @search="onSearch" />

                <a-button type="primary" @click="resetting">
                    重置</a-button>

                <a-button icon="download" type="primary" @click="downloadR">
                    资料导出</a-button>
            </a-space>
        </div>
        <a-table bordered :columns="columns" :data-source="data" rowKey="id" :scroll="{ x: 1800 }" :pagination="{
            total: count,
            current: listParams.page,
            pageSize: listParams.limit,
            showTotal: (total) => `共 ${total} 条`,
        }" @change="pageChange">

            <span slot="user_name" slot-scope="text, record, index">
                <a @click="showMoadl(record)">{{ text }}</a>
            </span>

            <span slot="check_status" slot-scope="text">
                <a-tag color="blue" v-if="text == 3">
                    未打款
                </a-tag>
                <a-tag color="green" v-else-if="text == 5">
                    已打款
                </a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
                <a-space v-if="record.check_status != 5">
                    <a-button type="primary" size="small" @click="payMoadl(record)">打款</a-button>
                </a-space>
            </span>
        </a-table>

        <a-modal title="打款凭证" :visible="visible" cancelText="取消" okText="确认" @ok="handleOk" @cancel="visible = false"
            :ok-button-props="{ props: { disabled: footerFlag } }">
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-form-item label="打款时间">
                    <a-date-picker show-time v-model="payFromData.pay_time" @change="onChange"
                        :disabled-date="disabledStartDate" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"
                        :locale="locale" placeholder="不选则默认当前时间" />
                </a-form-item>
                <a-form-item label="上传打款凭证">
                    <a-upload :action="uploadImage" list-type="picture-card" :file-list="fileList" @preview="handlePreview"
                        @change="handleChange">
                        <div v-if="fileList.length < 1">
                            <a-icon type="plus" />
                            <!-- <div class="ant-upload-text">
                               打款凭证上传
                            </div> -->
                        </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                        <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                </a-form-item>
            </a-form>
        </a-modal>

        <!-- 上传人信息 -->
        <a-modal title="上传人资料" :visible="visible2" @cancel="visible2 = false" :width="1000" :footer="null">

            <a-card title="用户资料" style="width: 900px">
                <a-row>
                    <a-col :span="8">
                        姓名：{{ uploaderInformation.user_realname }}
                    </a-col>
                    <a-col :span="8">
                        性别：{{ uploaderInformation.sex == 1 ? '男' : '女' }}
                    </a-col>
                    <a-col :span="8">
                        昵称：{{ uploaderInformation.user_nickname }}
                    </a-col>
                </a-row>
                <br>
                <a-row>
                    <a-col :span="8">
                        手机号：{{ uploaderInformation.mobile }}
                    </a-col>
                    <a-col :span="8">
                        账号：{{ uploaderInformation.user_login }}
                    </a-col>
                    <a-col :span="8">
                        身份证：{{ uploaderInformation.id_card }}
                    </a-col>
                </a-row>
                <br>
                <a-row>
                    <a-col :span="8">
                        银行卡号：{{ uploaderInformation.bank_no }}
                    </a-col>
                    <a-col :span="16">

                        开户行(精确到支行)：{{ uploaderInformation.bank_name }}
                    </a-col>

                </a-row>
            </a-card>
        </a-modal>

    </div>
</template>

<script>
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


import {
    materialFinanceList,
    materialCatShowList,
    uploadImage,
    materialPay
} from "@/apis/index";
import { materialExportResult } from "@/apis/down";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
const columns = [
    {
        title: "资料名称",
        dataIndex: "title",
        scopedSlots: { customRender: "title" },
        width: 200,
        fixed: 'left'
    },

    {
        title: "上传人",
        dataIndex: "user_name",
        scopedSlots: { customRender: "user_name" },
        width: 160,
    },

    {
        title: "视频数量",
        dataIndex: "videos",
        scopedSlots: { customRender: "videos" },
        customRender: (text) => {
            return text.count
        },

    },
    {
        title: "文件数量",
        dataIndex: "files",
        scopedSlots: { customRender: "files" },
        customRender: (text) => {
            return text.count
        },
    },
    {
        title: "标签",
        dataIndex: "flag",
        scopedSlots: { customRender: "flag" },
    },
    {
        title: "上传时间",
        dataIndex: "create_time",
        scopedSlots: { customRender: "create_time" },
        customRender: (text) => {
            // return text.count
            return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
        },
        width: 180
    },
    {
        title: "审核时间",
        dataIndex: "check_time",
        scopedSlots: { customRender: "check_time" },
        customRender: (text) => {
            // return text.count
            return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
        },
        width: 180
    },
    {
        title: "确认时间",
        dataIndex: "sure_time",
        scopedSlots: { customRender: "sure_time" },
        customRender: (text) => {
            // return text.count
            return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
        },
        width: 180
    },
    {
        title: "打款时间",
        dataIndex: "pay_time",
        scopedSlots: { customRender: "pay_time" },
        customRender: (text) => {
            // return text.count
            if (text) {
                return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
            } else {
                return ''
            }

        },
        width: 180

    },
    {
        title: "审核员",
        dataIndex: "admin_name",
        scopedSlots: { customRender: "admin_name" },
    },
    {
        title: "状态",
        dataIndex: "check_status",
        scopedSlots: { customRender: "check_status" },
        width: 100,
        fixed: 'right'
    },
    {
        title: "分值",
        dataIndex: "score",
        scopedSlots: { customRender: "score" },
        width: 100,
        fixed: 'right'
    },
    {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" },
        width: 100,
        fixed: 'right'
    },
];

const check_status = [
    {
        title: "全部",
        value: 0,
    },
    {
        title: "已打款",
        value: 1,
    },
    {
        title: "未打款",
        value: 2,
    },
];

export default {
    data() {
        return {
            uploadImage,
            columns,
            data: [],
            check_status,
            locale,
            time: [],
            listParams: {
                nickname: '',
                status: undefined,
                cat_id: undefined,
                start_time: '',
                end_time: '',
                page: 1,
                limit: 10,
                flag: undefined
            },
            count: 0,
            classify: [],
            visible: false,

            payFromData: {
                id: '',
                pay_time: '',
                pay_image: ''
            },



            previewVisible: false,
            previewImage: '',
            fileList: [],

            footerFlag: true,

            // 上传人
            visible2: false,
            uploaderInformation: '',

            confirmTime:''
        }
    },
    created() {
        materialCatShowList().then(res => {
            this.classify = res.data.list
        })
        this.getmaterialIndex()
    },
    methods: {
        disabledStartDate(startValue) {
            // console.log(this.confirmTime)
            return startValue < moment(this.confirmTime)
        },


        resetting() {
            this.listParams = {
                nickname: '',
                status: undefined,
                cat_id: undefined,
                start_time: '',
                end_time: '',
                page: 1,
                limit: 10,
                flag: undefined
            }
            this.time = []
            this.getmaterialIndex()
        },


        downloadR() {
            materialExportResult({ ...this.listParams, is_finance: 1 }).then((res) => {
                if (res.status == 200) {
                    let blob = new Blob([res.data], {
                        type: `application/csv`, //word文档为msword,pdf文档为pdf
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    let link = document.createElement("a");
                    let fname = `资料列表.csv`; //下载文件的名字
                    link.href = objectUrl;
                    link.setAttribute("download", fname);
                    document.body.appendChild(link);
                    link.click();
                }
            });
        },

        // 上传人信息
        showMoadl(rec) {
            this.visible2 = true
            this.uploaderInformation = rec.user
            // console.log(rec)
        },

        // 上传模块
        handleCancel() {
            this.previewVisible = false;
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange(e) {
            this.fileList = e.fileList;
            if (e.file.status == 'done') {
                this.payFromData.pay_image = e.file.response.data.id
            }
        },
        // 提交的
        onChange(e) {
            console.log(e)
        },
        handleOk() {
            // console.log(this.payFromData)
            materialPay(this.payFromData).then(res => {
                if (res.code == 1) {
                    this.getmaterialIndex()
                    this.visible = false
                }
            })

        },
        payMoadl(rec) {
            this.visible = true
            this.payFromData.id = rec.id

            this.confirmTime=moment(rec.sure_time * 1000).format("YYYY-MM-DD HH:mm:ss")

            if (rec.check_status == 5) {
                this.fileList = [
                    {
                        uid: rec.pay_image,
                        name: '打款凭证',
                        status: 'done',
                        url: rec.pay_image_url
                    }
                ]
                this.payFromData.pay_time = moment(rec.pay_time * 1000).format("YYYY-MM-DD HH:mm:ss")
                this.payFromData.pay_image = rec.pay_image
                this.footerFlag = true
            } else {
                this.fileList = []
                this.payFromData.pay_time = ''
                this.payFromData.pay_image = ''
                this.footerFlag = false
            }

        },

        onSearch() {
            this.getmaterialIndex()
        },
        timeChange(e) {
            this.listParams.start_time = e[0]
            this.listParams.end_time = e[1]
        },
        getmaterialIndex() {
            materialFinanceList(this.listParams).then(res => {
                this.data = res.data.list
                this.count = res.data.count
            })
        },
        pageChange(a) {
            this.listParams.page = a.current;
            this.getmaterialIndex()
        }
    }
}
</script>
<style lang="less" scoped>
.main {
    .actionBar {
        padding: 30px 0;
    }
}
</style>